<template>
  <div style="display: flex;
    flex-direction: column;
    align-self: center;
    ">
    <div
      class="useful-content-title"
      @click="categoryTitleClick(contentData.title)"
    >{{contentData.title}}</div>
    <div
      :class="detailIndex <4 ? 'useful-content-detail':''"
      v-for="(detailItem, detailIndex) in contentData.data"
      :key="detailIndex"
      @click="itemClick(contentData.title,detailItem.title)"
    >
      <div v-if="detailIndex < 4">{{detailItem.title}}</div>
    </div>
    <div class="useful-content-show-more" v-if="contentData.data.length > 4">[Daha Fazla]</div>
  </div>
</template>

<script>
export default {
  name: "UsefulContentListMenu",
  props: ["contentData", "buyerOrFreelancer"],
  methods: {
    categoryTitleClick: function(title) {
      if (this.buyerOrFreelancer == "buyer") {
        this.$router.push({
          name: "/destek/kategori/" + title,
          params: { buyerOrFreelancer: "buyer", selectedCategory: title }
        });
      } else {
        this.$router.push({
          name: "helpcategorydetails",
          params: { buyerOrFreelancer: "freelancer", selectedCategory: title }
        });
      }
    },
    itemClick: function(title, detail) {
      if (this.buyerOrFreelancer == "buyer") {
        this.$router.push({
          name: "helpcategorydetails",
          params: {
            buyerOrFreelancer: "buyer",
            selectedCategory: title,
            selectedCatDetail: detail
          }
        });
      } else {
        this.$router.push({
          name: "helpcategorydetails",
          params: {
            buyerOrFreelancer: "freelancer",
            selectedCategory: title,
            selectedCatDetail: detail
          }
        });
      }
    }
  }
};
</script>
<style scoped lang="scss">
.useful-content-title {
  font-size: 26px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2d3640;
  margin-top: 20px;
  cursor: pointer;
}

.useful-content-detail {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #6d7174;
  max-width: 250px;
  cursor: pointer;
  margin-top: 15px;
  &:hover {
    color: #00a575;
  }
}

.useful-content-show-more {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #fd4056;
  margin-top: 20px;
  cursor: pointer;
}
</style>