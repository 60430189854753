<template>
  <div style="display: flex;flex-direction: column;align-items: center;">
    <div
      style="display:flex; justify-content:center; align-items:center; margin-top:90px; flex-direction:column;"
    >
      <div class="inner-headers">Yararlı bulabileceğin konu başlıkları</div>
      <div class="inner-header-description">En çok sorduğun konuları bir araya topladık. 😇</div>
    </div>
    <div
      style="min-width:85%;display:flex;justify-content:space-between;flex-direction:row;align-self:center;margin-top:50px"
    >
      <div
        v-for="(item,index) in usefulContentData"
        :key="index"
        style="display:flex; flex-direction:column;"
      >
        <img onload="SVGInject(this)"
          :src="'https://gcdn.bionluk.com/site/cicons/'+item.imageName"
             class="big-icon"
        />

      </div>
    </div>

    <div class="new-ticket-bar-container">
      <div class="support-icon-container">
        <img
          src="https://gcdn.bionluk.com/site/cicons/y/ic-support.svg"
          style="width:24px;height:24px;"
        />
      </div>
      <p class="new-ticket-bar-text">
        Yardım istediğin konuyu destek makalelerimiz arasında bulamadın mı? O zaman destek talebi oluşturabilir ve taleplerini
        <span
          style="font-weight:bold"
        >Destek Taleplerim</span> bölümünden takip edebilirsin.
      </p>
      <div class="new-ticket-button" @click="newSupportTicketClick">
        <span class="new-ticket-button-text">Destek Talebi Oluştur</span>
      </div>
    </div>
  </div>
</template>

<script>
import UsefulContentListMenu from "./UsefulContentListMenu";

export default {
  name: "UsefulContent",
  components: { UsefulContentListMenu },
  data() {
    return {
      usefulContentData: [
        {
          imageName: "ic-gears.svg",
          title: "İşleyiş",
          showMore: false,
          data: [
            {
              title: "Nasıl çalışıyor"
            },
            {
              title: "Süreç nasıl işliyor?"
            },
            {
              title: "Hizmet satın almak"
            },
            {
              title: "Freelancer olmak ve satış yapmak"
            }
          ]
        },
        {
          imageName: "ic-bi.svg",
          title: "Bionluk",
          showMore: true,
          data: [
            {
              title: "Bionluk nedir"
            },
            {
              title: "Genel terimler"
            },
            {
              title: "Hesap oluşturmak"
            },
            {
              title: "Hesap yönetimi"
            }
          ]
        },
        {
          imageName: "ic-ruler.svg",
          title: "Genel kurallar",
          showMore: true,
          data: [
            {
              title: "Şüpheli mesajlar"
            },
            {
              title: "Hizmet bedeli oranı"
            },
            {
              title: "Yanıltıcı profil bilgileri"
            },
            {
              title: "İletişim bilgisi paylaşımı"
            }
          ]
        },
        {
          imageName: "ic-secure.svg",
          title: "Güven ve güvenlik",
          showMore: false,
          data: [
            {
              title: "Ödeme & iletişimi"
            },
            {
              title: "Sipariş iptal politikası"
            },
            {
              title: "Paylaşılan işlerin güvenliği"
            },
            {
              title: "Kişisel bilgilerin güvenliği"
            }
          ]
        }
      ]
    };
  },
  methods: {
    newSupportTicketClick: function() {
      this.$router.push("/newsupportticket");
    }
  }
};
</script>
<style lang="scss" scoped>

  .big-icon /deep/{
    width: 50px;
    height: 46px;
    path {
      fill: #fd4056;
    }
  }

.support-icon-container {
  min-width: 60px;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  box-shadow: 0 3px 6px 0 rgba(253, 64, 86, 0.2);
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  display: flex;
}
.new-ticket-bar-container {
  margin-top: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
  border: solid 1px #eaedf2;
  background-color: #00a575;
  padding: 20px 20px;
  max-width: 85%;
  align-self: center;
}
.new-ticket-bar-text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  margin: 0px 20px;
  color: #ffffff;
}
.new-ticket-button {
  height: 44px;
  border-radius: 2px;
  border: solid 1px #ffffff;
  background-color: transparent;
  min-width: 190px;
  cursor: pointer;
  display:flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: transparent;
  }
}
.new-ticket-button-text {
  font-family: "sofia-pro";
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.inner-headers {
  font-size: 34px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2d3640;
}
.inner-header-description {
  margin-top: 10px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #5e6b79;
}
</style>
