<template>
  <div>
    <div v-if="!pageLoaded" style="padding-top:70px; height: 280px;">
      <div class="loader" style="text-align: center;"></div>
    </div>
    <div v-else style="width: 1170px; margin:0 auto; padding-top:50px; padding-bottom: 50px;">
      <div style="display: flex; justify-content: space-between">
        <div>
          <h1 style="margin-top: 0;" class="help-header">Hangi konuda yardıma ihtiyacın var?</h1>
          <h3 class="help-header-description">İster alıcı ister freelancer ol, aklına takılan her şey için Bionluk burada. 🖖🏻</h3>
        </div>
        <custom-button v-if="showTicketsButton" @click="$router.push('/tickets')" buttonType="dark" button-style="width:188px; height:44px;font-size: 14px;font-weight: 600;">Destek Taleplerim</custom-button>
      </div>
      <div class="card-container">
        <router-link :to="'/destek/kategori/'+category.slug" v-for="(category, i) in supportCategories.slice(0,2)" :key="i" class="card-item">
          <img :src="category.icon" style=" width: 169px;object-fit: contain; margin-right:30px;"/>
          <div>
            <div class="card-description-header">{{category.title}}</div>
            <div class="card-description-detail">{{category.text}}</div>
          </div>
        </router-link>
      </div>

      <Faqs :contentData="faqData" :impression-u-u-i-d="impressionUUID" />

      <div style="display: flex;flex-direction: column;align-items: center;">
        <div style="display:flex; justify-content:center; align-items:center; margin-top:90px; flex-direction:column;">
          <div class="inner-headers">Yararlı bulabileceğin konu başlıkları</div>
          <div class="inner-header-description">En çok sorduğun konuları bir araya topladık. 😇</div>
        </div>
        <div style="min-width:85%;display:flex;justify-content:space-between;flex-direction:row;align-self:center;margin-top:50px">
          <div v-for="(category,index) in supportCategories.slice(2,6)" :key="index" style="flex:1; display:flex; flex-direction:column; ">
            <img onload="SVGInject(this)" :src="category.icon" class="big-icon"/>
            <div style="display: flex;flex-direction: column;">
              <router-link :to="'/destek/kategori/'+category.slug" class="useful-content-title">{{category.title}}</router-link>
              <div
                :class="index < 4 ? 'useful-content-detail':''"
                v-for="(sub_category, subi) in category.sub_categories"
                :key="subi"
              >
                <router-link :to="'/destek/kategori/'+sub_category.slug" v-if="subi < 4">{{sub_category.title}}</router-link>
              </div>
              <router-link :to="'/destek/kategori/'+category.slug" class="useful-content-show-more" v-if="category.sub_categories.length > 4">[Daha Fazla]</router-link>
            </div>
          </div>
        </div>

        <div class="new-ticket-bar-container">
          <div class="support-icon-container">
            <img
              src="https://gcdn.bionluk.com/site/cicons/ic-support.svg"
              onload="SVGInject(this)"
              class="support-icon"
            />
          </div>
          <p class="new-ticket-bar-text">
            Yardım istediğin konuyu destek makalelerimiz arasında bulamadın mı? O zaman destek talebi oluşturabilir ve taleplerini
            <router-link to="tickets"
              style="font-weight:bold; color: white!important;"
            >Destek Taleplerim</router-link> bölümünden takip edebilirsin.
          </p>
          <router-link to="tickets?tab=new" class="new-ticket-button">
            <span  class="new-ticket-button-text">Destek Talebi Oluştur</span>
          </router-link>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import FooterNav from "../../../../components/shared/navFooter";
import CustomInput from "../../../../components/CustomInput";
import CustomButton from "../../../../components/CustomButton";
import Faqs from "../../../../components/Faqs";
import UsefulContent from "../../../../components/UsefulContent";

export default {
  name: "src-pages-body-staticContents-support-list-v1",
  components: { FooterNav, CustomInput,CustomButton, Faqs, UsefulContent },
  data() {
    return {
      impressionUUID:null,
      showTicketsButton:false,

      faqData: {
        title: "Merak edilen sorular?",
        text: "En çok sorduğun konuları bir araya topladık. 😇",
        accordionData: []
      },

      pageLoaded: false,
      supportCategories: [],



      newTicketArea: false,
      questions: [
        {
          label: "Freelancer'ım",
          id: "freelancer"
        },
        {
          label: "Alıcıyım",
          id: "buyer"
        }
      ],
      selectedQuestionId: null,
      ticketCategories: [],
      selectedTicketCategory: null,
      ticketCategories2: [],
      selectedTicketCategory2: null,
      disableRadioButton: false,
      ticketDescription: "",
      needMoreButtonClicked: false,

      // ticket
      title: null,
      description: null,
      files: [],
      uploadResponseStatus: null,
      timeStamp: null,
      ticketCategoriesLoader: true,
      ticketCategories2Loader: true,
      loaderPart: false,

      technicalProblems: {
        app: ["Masaüstü Tarayıcısı", "Mobil Uygulama", "Telefon Tarayıcısı"],
        browsers: [
          "Google Chrome",
          "Internet Explorer - Edge",
          "Mozilla Firefox",
          "Opera",
          "Safari",
          "Diğer"
        ],
        platforms: ["IOS", "ANDROID"]
      },
      technicalProblemAnswers: {
        app: null,
        browser: null,
        platform: null,
        version: null
      }
    };
  },

  methods: {
    cardClick: function(buyerFreelancer) {
      this.$router.push("/destek/" + buyerFreelancer);
      // this.$router.push({
      //   name: "/destek/"  + buyerFreelancer,
      //   params: { buyerOrFreelancer: buyerFreelancer }
      // });
    },
    selectNewTicketArea(method) {
      if (this.user.uuid) {
        this.newTicketArea = method;
      } else {
        this.$router.push("/login");
      }
    },
    fetchDataToInit() {
      this.impressionUUID = this.Helper.generateUUID()
      this.api.content
        .retrieveSupportCategoriesv2()
        .then(({ data }) => {
          let result = data;

          if (result.success) {
            this.supportCategories = result.data.support_categories;
            this.faqData.accordionData = result.data.faqs;
            this.showTicketsButton = result.data.show_tickets_button;
          } else {
            this.$toasted.global.errorToast({
              description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE
            });
            this.$router.push(result.redirect_url);
          }
          this.pageLoaded = true;
        })
        .catch(err => {
          this.pageLoaded = true;
          this.$toasted.global.errorToast({
            description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE
          });
        });
    },

    getTicketCategories(parentId, question) {
      if (!parentId) {
        this.loaderPart = true;
        this.selectedQuestionId = question;
        this.disableRadioButton = true;
        this.ticketCategoriesLoader = true;
        this.ticketCategories2Loader = true;
      } else {
        this.ticketCategories2Loader = true;
      }
      this.api.content
        .getTicketCategories(parentId, question)
        .then(({ data }) => {
          let result = data;
          if (result.success) {
            if (!parentId) {
              this.ticketCategories = result.data.ticket_categories;
              this.ticketCategoriesLoader = false;
            } else {
              this.ticketCategories2 = result.data.ticket_categories;
              this.ticketCategories2Loader = false;
            }
          } else {
            this.$toasted.global.errorToast({
              description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE
            });
            this.$router.push(result.redirect_url);
          }
          this.disableRadioButton = false;
        })
        .catch(err => {
          this.disableRadioButton = false;
          this.$toasted.global.errorToast({
            description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE
          });
        });
    },

    clickNeedMoreButton() {
      this.title = {
        parent: this.selectedTicketCategory.name,
        child: this.selectedTicketCategory2.name
      };
      this.needMoreButtonClicked = true;
    },

    deleteFile(uploadID) {
      this.api.general
        .updateUploadStatus(uploadID, this.Constants.UPLOAD_STATUSES.DELETE)
        .then(({ data }) => {
          let result = data;

          if (result.success) {
            this.files = this.files.filter(function(file) {
              return file.externalID !== uploadID;
            });

            this.$toasted.global.infoToast({ description: result.message });
          } else {
            this.$toasted.global.errorToast({ description: result.message });
          }
        })
        .catch(err => {
          this.$toasted.global.errorToast({
            description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE
          });
        });
    },

    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.uploadResponseStatus = "started";
      this.timeStamp = +new Date();
      this.uploadFileToGoogle(
        files[0],
        this.Constants.UPLOAD_TYPES.TICKET,
        this.timeStamp
      )
        .then(result => {
          let file = {
            url: this.Constants.CDN_UPLOADS_G + result.uploadName,
            externalID: result.externalID,
            filename: result.filename
          };

          this.files.push(file);
          this.uploadResponseStatus = null;
          e.target.value = "";
        })
        .catch(err => {
          this.$toasted.global.errorToast({
            description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE
          });
          this.uploadResponseStatus = null;
          e.target.value = "";
        });
    },

    createTicket() {
      let uploadIDs = this.files.map(function(file) {
        return file.externalID;
      });
      let description = this.description;

      // Teknik Sorunlar secilirse mesajlar bu sekilde gidecek
      if (
        this.selectedTicketCategory2.id === 80 ||
        this.selectedTicketCategory2.id === 78
      ) {
        if (
          this.technicalProblemAnswers.app === "Masaüstü Tarayıcısı" ||
          this.technicalProblemAnswers.app === "Telefon Tarayıcısı"
        ) {
          description = `
              Problemin Gerçekleştiği Uygulama: ${this.technicalProblemAnswers.app} \n
              Problemin Gerçekleştiği Browser: ${this.technicalProblemAnswers.browser} \n
              Uygulama Versiyonu: ${this.technicalProblemAnswers.version} \n \n
              -------------------------------------------------------- \n
              Kullanici Mesajı: ${this.description}
              `;
        } else if (this.technicalProblemAnswers.app === "Mobil Uygulama") {
          description = `
              Problemin Gerçekleştiği Uygulama: ${this.technicalProblemAnswers.app} \n
              Problemin Gerçekleştiği Platform: ${this.technicalProblemAnswers.platform} \n
              Uygulama Versiyonu: ${this.technicalProblemAnswers.version} \n \n
              -------------------------------------------------------- \n
              Kullanici Mesajı: ${this.description}
              `;
        }
      }
      this.api.general
        .createTicket(
          this.title.parent + "/" + this.title.child,
          description,
          uploadIDs,
          this.$Progress
        )
        .then(({ data }) => {
          let result = data;

          if (result.success) {
            this.$toasted.global.infoToast({ description: result.message });
            let uuid = result.data.ticket.uuid;
            this.$router.push(`/tickets/${uuid}`);
          } else {
            this.$toasted.global.errorToast({ description: result.message });
          }
        })
        .catch(err => {
          this.$toasted.global.errorToast({
            description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE
          });
        });
    },

    resetState(isCase) {
      // 0 ise => 0 , 1 ve 2 yi calistiracak yani butun state'yi sifirlayacak.
      // Birinde olup digerinde olmayacak bir sey var ise break konularak tekrar yazilmali
      switch (isCase) {
        case 0:
          this.ticketCategories = [];
          this.selectedTicketCategory = null;
          this.ticketCategoriesLoader = true;
        case 1:
          this.ticketCategories2 = [];
          this.selectedTicketCategory2 = null;
          this.ticketCategories2Loader = true;
        case 2:
          this.ticketDescription = "";
          this.title = "";
          this.needMoreButtonClicked = false;
          this.files = [];
          break;
      }
    }
  },

  watch: {
    selectedTicketCategory(newVal, oldVal) {
      this.resetState(1);
      if (newVal) {
        this.getTicketCategories(newVal.id, this.selectedQuestionId);
      }
    },

    selectedTicketCategory2(newVal, oldVal) {
      this.resetState(2);
      if (newVal) {
        this.ticketDescription = newVal.description;
        this.technicalProblemAnswers = {
          app: null,
          browser: null,
          platform: null,
          version: null
        };
      }
    },
    selectedQuestionId(newVal, oldVal) {
      if (newVal && oldVal) {
        this.resetState(0);
      }
    },
    "technicalProblemAnswers.app": function(newVal, oldVal) {
      if (newVal && oldVal && newVal !== oldVal) {
        let technicalProblemAnswers = this.technicalProblemAnswers;
        technicalProblemAnswers.browser = null;
        technicalProblemAnswers.platform = null;
        technicalProblemAnswers.version = null;
        this.technicalProblemAnswers = { ...technicalProblemAnswers };
      }
    }
  },

  computed: {
    disableToSave() {
      return !this.title || !this.description;
    }
  },

  created() {
    document.body.scrollTop = 0;
    this.fetchDataToInit();
  }
};
</script>

<style scoped lang="scss">
.help-header {
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #2d3640;
}
.help-header-description {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #5e6b79;
}
.card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}
.card-item {
  display: flex;
  background-color: #ffffff;
  width: 41%;
  border-radius: 10px;
  min-height: 150px;
  padding: 40px;
  margin: 10px;
  align-items: center;
  border: solid 1px #eff3f8;
  box-shadow: 0 20px 20px 0 #e9ebf0;
  transition: all 0.2s;
  cursor: pointer;
  &:hover {
    padding: 50px;
    margin: 0;
    text-decoration: none;
  }
}
.card-description-header {
  font-size: 26px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2d3640;
}
.card-description-detail {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #5e6b79;
}


.big-icon /deep/{
  width: 50px;
  height: 46px;
  path {
    fill: #fd4056;
  }
}

.support-icon /deep/{
  width: 24px;
  height: 24px;
  path {
    fill: #00a575;
  }
}

.support-icon-container {
  min-width: 60px;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  box-shadow: 0 3px 6px 0 rgba(253, 64, 86, 0.2);
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  display: flex;
}
.new-ticket-bar-container {
  margin-top: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
  border: solid 1px #eaedf2;
  background-color: #00a575;
  padding: 20px 20px;
  max-width: 85%;
  align-self: center;
}
.new-ticket-bar-text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  margin: 0px 20px;
  color: #ffffff;
}
.new-ticket-button {
  height: 44px;
  border-radius: 2px;
  border: solid 1px #ffffff;
  background-color: transparent;
  min-width: 190px;
  cursor: pointer;
  display:flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: transparent;
    text-decoration: none;
    span{
      text-decoration: none;
    }
  }
}
.new-ticket-button-text {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.inner-headers {
  font-size: 34px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2d3640;
}
.inner-header-description {
  margin-top: 10px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #5e6b79;
}

.useful-content-title {
  font-size: 26px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2d3640;
  margin-top: 20px;
  cursor: pointer;
  &:hover{
    text-decoration: none;
  }
}

.useful-content-detail {
  font-size: 18px;

  line-height: 1.67;

  color: #6d7174;
  max-width: 250px;

  margin-top: 15px;
  &:hover {
    color: #00a575;
    text-decoration: none;
  }
  a{
    font-size: 18px;

    line-height: 1.67;

    color: #6d7174;
    max-width: 250px;

    margin-top: 15px;
    &:hover {
      color: #00a575;
      text-decoration: none;
    }
  }
}

.useful-content-show-more {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #fd4056;

  cursor: pointer;
}
</style>
